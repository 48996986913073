import { createI18n } from 'vue-i18n'

import messages from '/@admin/locale/en.json'

const i18n = createI18n({
  locale: 'en',
  messages: { en: messages },
})

export default i18n

const $t = i18n.global.t
export { $t, i18n }
