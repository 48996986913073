/* eslint-disable no-bitwise */
import { App, reactive } from 'vue'

type FlitsMessage = string

export interface FlitsItem {
  id?: string
  level: string
  message: FlitsMessage
  link: string | null
}

export class Flits {
  public items: FlitsItem[] = []

  constructor() {
    this.items = []
  }

  public addMultiple(items: FlitsItem[] | boolean): void {
    if (items === false) {
      return
    }

    items = items as FlitsItem[]

    if (!Array.isArray(items)) {
      items = [items]
    }

    items.forEach((item, key) => {
      setTimeout(() => {
        this.addSingle(item)
      }, key * 300 + 50)
    })
  }

  public addSingle(item: FlitsItem | string, ttl = 8_000): FlitsItem {
    if (typeof item === 'string') {
      item = { level: 'error', message: item } as FlitsItem
    }

    item.id = this.getUUID()
    this.items.push(item)

    setTimeout(() => {
      this.remove(item as FlitsItem)
    }, ttl)

    return item
  }

  private getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  public remove(item: FlitsItem): void {
    const index = this.items.findIndex((i) => {
      return i.id === item.id
    })

    this.items.splice(index, 1)
  }

  public onClick(item: FlitsItem) {
    this.remove(item)
  }

  public success(message: string, link: string | null = null): void {
    this.addSingle({
      level: 'success',
      message,
      link,
    })
  }

  public error(message: string, link: string | null = null): void {
    this.addSingle({
      level: 'error',
      message,
      link,
    })
  }

  public info(message: string, link: string | null = null): void {
    this.addSingle({
      level: 'info',
      message,
      link,
    })
  }
}

const flits = reactive(new Flits())

export default {
  install(app: App) {
    app.config.globalProperties.$flits = flits

    app.mixin({
      data: () => {
        return {
          flits: flits.items,
        }
      },
    })

    if (_state && _state.flits.length > 0) {
      flits.addMultiple(_state.flits)
    }
  },
}

export { flits }
