import { App, defineAsyncComponent } from 'vue'

export const registerComponents = (app: App) => {
  const modules = import.meta.glob('../components/*/index.vue')

  for (const path in modules) {
    const mod = modules[path]
    const parts = path.split('/')
    const name = parts[parts.length - 2]

    app.component(name, defineAsyncComponent(mod as any))
  }
}
